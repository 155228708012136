body {
  font-size: 10px;
  background-color: black;
  height: 100vh;
  width: 100vw;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.haiku {
  left: 2rem;
  position: fixed;
  top: 2rem;
  z-index: 100;
}

.line-1,
.line-2,
.line-3 {
  font-family: "Quicksand", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  margin: 2rem;
  color: white;
}
